import {
    get,
    post
} from "./config";
import Cookies from 'js-cookie'
let access_token = Cookies.get('access_token');
let obj = {
    'Blade-Auth': "bearer " + access_token
}
// http://192.168.110.54:8880/api/blade-auth/oauth/token?tenantId=000000&username=admin&password=21232f297a57a5a743894a0e4a801fc3&grant_type=captcha&scope=all&type=account
export const login = (data) => post(`/blade-auth/oauth/token?tenantId=000000&username=${data.username}&password=${data.password}&grant_type=captcha&scope=all&type=account`, {}, {
    'Captcha-Code': data.code,
    'Captcha-Key': data.key,
    Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});

export const registerAccout = (data) => post(`/blade-user/register`, data, {});
export const captcha = (data) => get(`/blade-auth/oauth/captcha`, data);
export const getComList = (data) => get(`/sso-apps/apps/mapDate`, data);

export const postList = (data) => get(`/blade-system/post/list`, data);
export const deptList = (data) => get(`/blade-system/dept/list`, data);
export const roleList = (data) => get(`/blade-system/role/list`, data);
export const appsList = (data) => get(`/sso-apps/apps/list`, data);
export const sendEmail = (data) => get(`/blade-user/sendEmail?email=${data.email}`);

export const getUserInfo = (data) => get(`/blade-user/info`, data);
export const emitInfo = (data) => post(`/blade-user/update-info`, data, {});
export const getQNToken = (data) => post(`/blade-user/getQNToken`, data, {});
export const emitPwd = (data) => post(`/blade-user/update-password?oldPassword=${data.oldPassword}&newPassword=${data.newPassword}&newPassword1=${data.newPassword1}`, "", {});
export const getJWTToken = (data) => get(`/sso-apps/apps/getJWTToken`, data, {});
export const refreshToken = (data) => post(`/blade-auth/oauth/token?tenantId=000000&refresh_token=${data.refresh_token}&grant_type=${data.grant_type}`, data, {
    'Tenant-Id': '000000',
    'Authorization':'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
});
export const getOtherInfoVO = (data) => get(`/sso-apps/apps/getOtherInfoVO`, data, {});

export const actionList = (data) => get(`/blade-desk/notice/list`, "", );
export const actionDetail = (data) => get(`/blade-desk/notice/detail`, "", );
export const loginAuth = (data) => get(`https://zhwy.nprc.net/loginAuth`, "", );

export const bindAppByUser = ((data) => post("/sso-apps/apps/bindAppByUser", data,{"Content-Type": "application/json;charset=UTF-8"}));
export const updateBindStatus = (data) => get(`/sso-apps/apps/updateBindStatus`, "");
export const unbind = (data) => get(`/sso-apps/apps/unbind`, data);
export const uploadImg = (data) => post(`/file/upload-img`, data,{"Content-Type": "multipart/form-data"});
// export const uploadImg = (data) => post(`/file/upload-img`, data);

