import axios from "axios";
import store from '@/store';
import router from '@/router';
import {
  ElMessage
} from "element-plus";
// axios.defaults.baseURL = 'https://npfy.3dy.me/'
import QS from "qs";
import Cookies from 'js-cookie'
import NProgress from 'nprogress';

// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use((config) => {
  // console.log(config)
  // NProgress.start();
  let access_token = Cookies.get('saber-access-token');
  if (config.url.indexOf("grant_type=captcha&scope=all&type=account") == -1 && config.url.indexOf("/blade-auth/oauth/captcha") == -1) {
    if (access_token) {
      config.headers['Blade-Auth'] = 'bearer ' + access_token;
    }
    // if (config.url.indexOf('file/upload-img') != -1) {
    //   config.headers['Content-Type'] = 'multipart/form-data'
    // }
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// // //http response 拦截
axios.interceptors.response.use(res => {
  //关闭 progress bar
  NProgress.done();
  // console.log(res)
  //获取状态码
  const status = res.data.code || res.status;
  // const statusWhiteList = website.statusWhiteList || [];
  const message = res.data.msg  || '未知错误';
  //如果是401则跳转到登录页面
  // if (status === 401) {
  //   console.log(1111)
  // }
  // 如果请求为非200否者默认统一处理
  if (status !== 200) {
     ElMessage({
      message: message,
      type: 'error'
    });
    return Promise.reject(new Error(message))
  }
  return res;
}, (error) => {
  // console.log(error)
  // console.log(error.response)
  const status = error.response?.data.code?error.response?.data.code:error.response?.status;
  const message = error.response?.data?.msg ||  error.response?.msg ||error.response?.data.error_description || '未知错误';
  // const message = '未知错误';

  if (status === 401) {
    ElMessage({
      message: message,
      type: "error",
      duration: 5 * 1000,
      offset: 120
    });
  store.dispatch('FedLogOut').then(() => router.push({path: '/login'}));
  }
  if (status != 200) {
    ElMessage({
      message: message,
      type: "error",
      duration: 5 * 1000,
      offset: 120
    });
  }
  return Promise.reject(new Error(error));
});



// import {getCookie} from "@/utils";

function msgcode(url, val = "接口报错") {
  // console.log(val)
  const status = val.response?.code?val.response?.code:val.status;
  // const statusWhiteList = website.statusWhiteList || [];
  const message = val.response?.data?.msg || val.response?.data?.error_description || '未知错误';
  // console.log(val)
  if (status != 200) {
    ElMessage({
      message: message,
      type: "error",
      duration: 5 * 1000,
      offset: 120
    });
  }
}

export function get(url, params = {}, headers = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,

      }, {
        headers
      })
      .then((res) => {
        // console.log(res)
        // msgcode(url, res);
        resolve(res.data);
      })
      .catch((err, res) => {
        // console.log(err.response)
        // msgcode(url, err);
        reject(err.response);
      });
  });
}
export function post(url, params = {}, headers = "") {
  if (headers) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers
        })
        .then((res) => {
          // console.log(res)
          // msgcode(url, res);
          resolve(res.data);
        })
        .catch((err) => {
          // msgcode(url, err);
          reject(err.data);
        });
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params))
      .then((res) => {
        // console.log(res)
        // msgcode(url, res);
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err)
        // msgcode(url, err);
        reject(err.data);
      });
  });
}